.image-slider {
  width: 100%;
  overflow: hidden; /* Prevents horizontal scroll */
  margin: 0 auto;
  position: relative; /* Ensure relative positioning for absolute children */
  padding-bottom: 40px; /* Add space for dots below the image */
}

.image-slider img {
  width: 100%;
  height: auto;
  max-height: 600px; /* Adjust this value as needed */
  object-fit: cover; /* Ensures the entire image is visible without cropping use contain later */
}

/* Custom styles for slick dots */
.slick-slider {
  padding-bottom: 0px; /* Add space for dots below the image */
}

.slick-dots {
  position: absolute;
  bottom: -30px; /* Move the dots below the image */
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px; /* Add some padding for spacing */
}

.slick-dots li {
  margin: 0 5px; /* Adjust spacing between dots */
}

.slick-dots li button:before {
  font-size: 12px;
  color: black; /* Adjust the color as needed */
}

.slick-dots li.slick-active button:before {
  color: red; /* Adjust the active dot color as needed */
}
/* Responsive styles */
@media (max-width: 768px) {
  .image-slider img {
      max-height: 300px; /* Adjust max-height for smaller screens */
  }

  .slick-dots {
      bottom: -20px; /* Adjust position for smaller screens */
  }

  .slick-dots li button:before {
      font-size: 10px; /* Adjust dot size for smaller screens */
  }
}
