.product-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: 100vh; /* Full viewport height to center vertically */
  background-color: #f5f5f5; /* Light grey background */
  padding-bottom: 30px;
  padding: 20px; /* Add padding for better spacing on small screens */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.product-frame {
  background-color: white;
  padding: 20px;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 800px; /* Maximum width of the frame */
  width: 100%;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.product-details-section {
  text-align: left;
}

/* Responsive styles */
@media (max-width: 768px) {
  .product-container {
    padding: 10px; /* Adjust padding for small screens */
    min-height: auto; /* Allow the height to adjust */
  }

  .product-frame {
    padding: 15px; /* Adjust padding for small screens */
  }

  .product-details-section {
    text-align: center; /* Center align text for better readability on small screens */
  }
}
