.container {
  display: flex;
  flex-direction: column;
  font-family: Butler;

}

.searchContainer {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #f5f5f5;
}

.searchInput {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.clothingItemsContainer {
  padding: 20px;
}

.clothingTitle {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  font:
}

.clothingCardsWrapper {
  overflow: hidden;
}

.clothingCards {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.clothingCards::-webkit-scrollbar {
  display: none;
}

.clothingCardLink {
  text-decoration: none;
  color: inherit;
  flex: 0 0 auto;
  margin-right: 20px;
}

.sliderContainer {
  position: relative;
  width: 100%;
  height: 2px;
  background-color: #e0e0e0;
  margin: 20px 0;
}

.sliderFill {
  position: absolute;
  height: 100%;
  background-color: #808080;
  left: 0;
  top: 0;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background: transparent;
  outline: none;
  opacity: 1;
  margin: 0;
  padding: 0;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 100px;
  height: 5px;
  background: #808080;
  cursor: pointer;
  border-radius: 0;
}

.slider::-moz-range-thumb {
  width: 10px;
  height: 10px;
  background: #808080;
  cursor: pointer;
  border-radius: 0;
}
/* Responsive styles */
@media (max-width: 78px) {
  .clothingItemsContainer {
    padding: 10px;
  }

  .clothingTitle {
    font-size: 20px;
  }

  .clothingCardsWrapper {
    padding: 10px;
  }

  .clothingCards {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }

  .clothingCardLink {
    flex: 0 0 auto;
    margin-right: 10px;
  }

  .sliderContainer {
    margin: 10px 0;
  }

  .slider::-webkit-slider-thumb,
  .slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
  }
}