.sidebar {
    position: fixed;
    top: 0;
    left: -100%;
    width: 250px;
    height: 100%;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0,0,0,0.3);
    transition: left 0.3s ease;
    z-index: 1001;
    font-family: Butler;

}

.open {
    left: 0;
}

.sidebarContent {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.closeButton {
    align-self: flex-end;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.sidebarLink {
    margin: 10px 0;
    text-decoration: none;
    color: black;
    font-size: 18px;
    font-weight: 500;
}

.dropdown {
    position: relative;
}

.dropdownContent {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
}

.dropdownContent a {
    margin: 5px 0;
}
