.card {
    width: 100%; /* Full width of the grid cell */
    height: auto; /* Adjust height automatically */
    background-color: white;
    text-align: left;
    margin: 0 0 20px; /* Remove horizontal margin */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 8px;
    overflow: hidden;
}

.imageContainer {
    width: 100%;
    height: 0;
    padding-bottom: 120%; /* Aspect ratio for the image */
    overflow: hidden;
    position: relative;
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.info {
    padding: 10px;
    flex-grow: 1; /* Allow content to grow */
}

.name {
    font-size: 16px; /* Adjusted font size */
    color: #000; /* Black color for the name */
    font-weight: bold;
    margin: 0;
    padding: 5px 0;
}

.price {
    font-size: 14px; /* Adjusted font size */
    color: #555; /* Dark gray color for the price */
    margin: 0;
    padding: 5px 0;
}

.cardLink {
    text-decoration: none;
    color: inherit;
}
@media (max-width: 768px) {
    .card {
        margin-bottom: 10px;
    }

    .name {
        font-size: 14px;
    }

    .price {
        font-size: 12px;
    }
}