.container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    font-size: 48px;
    padding: 50px;
}
/* Responsive styles */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        height: auto;
        padding: 20px;
        font-size: 24px;
    }
}
