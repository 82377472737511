.review-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    overflow-x: hidden; /* Prevent horizontal overflow */

}

.review-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    color: #000; /* Black color for the heading */
}

.review-container h2 span {
    margin-right: 10px;
}

.reviews {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.review-card {
    border: 1px solid #e0e0e0; /* Light gray border */
    border-radius: 8px;
    padding: 20px;
    background-color: #fff; /* White background */
}

.review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.review-header h3 {
    font-size: 18px;
    margin: 0;
    color: #000; /* Black color for the name */
}

.review-header p {
    font-size: 14px;
    color: #757575; /* Dark gray color for the date */
    margin: 0;
}

.review-body {
    margin-top: 10px;
}

.rating {
    font-size: 16px;
    color: #ff9800; /* Orange color for the stars */
    margin-bottom: 10px;
}

.load-more {
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 20px auto 0;
    padding: 10px;
    border: none;
    background-color: #000; /* Black background for the button */
    color: #fff; /* White text color for the button */
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
}

.load-more:hover {
    background-color: #333; /* Darker shade for hover effect */
}
/* Responsive styles */
@media (max-width: 768px) {
    .review-container {
        padding: 10px;
    }

    .review-container h2 {
        font-size: 20px;
        flex-direction: column;
        align-items: flex-start;
    }

    .reviews {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .review-card {
        padding: 10px;
    }

    .review-header h3 {
        font-size: 16px;
    }

    .review-header p {
        font-size: 12px;
    }

    .rating {
        font-size: 14px;
    }

    .load-more {
        max-width: 100%;
        padding: 8px;
        font-size: 14px;
    }
}
