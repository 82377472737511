.siteFooter {
    background-color: #fff8f4; /* Match background color */
    padding: 40px 20px;
    font-family: 'Lora', serif; /* Ensure this font is imported */
    color: #333;
}

.footerContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footerSection {
    flex: 1;
    min-width: 250px;
    margin: 10px;
}

.footerSection h4 {
    font-size: 18px;
    margin-bottom: 15px;
    color: #000;
}

.footerSection p {
    font-size: 14px;
    line-height: 1.6;
}

.footerSection ul {
    list-style: none;
    padding: 0;
}

.footerSection ul li {
    margin-bottom: 10px;
}

.footerSection ul li a {
    color: #333;
    text-decoration: none;
    font-size: 14px;
}

.footerSection ul li a:hover {
    text-decoration: underline;
}

.logo h2 {
    font-family: 'Lora', serif;
    font-size: 24px;
    color: #000;
    margin: 20px 0;
}

.socialIcons {
    display: flex;
    gap: 10px;
}

.socialIcons a {
    color: #333;
    font-size: 20px;
    text-decoration: none;
}

.socialIcons a:hover {
    color: #ff0000;
}

.subscribe {
    display: flex;
    align-items: center;
}

.subscribe input[type="email"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
    flex: 1;
}

.subscribe button {
    padding: 10px 20px;
    background-color: #333;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 0 4px 4px 0;
}

.subscribe button:hover {
    background-color: #ff0000;
}

.footerBottom {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
}

/* Responsive styles */
@media (max-width: 768px) {
    .footerContent {
        flex-direction: column;
        align-items: flex-start;
    }

    .footerSection {
        min-width: 100%;
        text-align: left;
    }

    .socialIcons {
        justify-content: flex-start;
        display: flex;
        flex-direction: row;
    }

    .subscribe {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .subscribe input[type="email"], .subscribe button {
        border-radius: 4px;
        margin: 5px 0;
        width: 100%;
    }

    .footerBottom {
        text-align: left;
        width: 100%;
    }
}
