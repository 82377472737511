.filterContainer {
    margin-bottom: 20px;
    font-family: Butler;

  }
  
  .sizeSelect, .sortSelect {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  h2 {
    display: flex;
    align-items: center;
  }
  
  h2 .fa-icon {
    margin-right: 10px;
  }
  /* Responsive styles */
@media (max-width: 768px) {
    .filterContainer {
      padding: 10px;
      background-color: #f5f5f5;
      border-radius: 4px;

    }
  
    .sizeSelect, .sortSelect {
      font-size: 14px;
      
    }
  
    h2 {
      font-size: 18px;
    }
  }