body {
  margin: 0;
  line-height: 1.5em;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

/* Responsive styles */
@media (max-width: 768px) {
  .header {
      padding: 12px;
  }
  html, body {
    overflow-x: hidden;
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


